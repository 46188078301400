import React, { useState, useEffect } from "react"
import Seo from "../components/seo"
import samplePageLinks from "../projectsData.json"
import Header from "../components/header"
import Footer from "../components/footer"
import Layout from "../components/layout"

const ProjectGallery = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const [project, setProject] = useState([])
  const [projectCampaignType, setProjectCampaignType] = useState([])
  const [projectInfo, setProjectInfo] = useState({})
  var col = 1
  useEffect(() => {
    setProject(samplePageLinks)
    var flags = [],
      output = [],
      l = samplePageLinks.length,
      i
    for (i = 0; i < l; i++) {
      if (flags[samplePageLinks[i].projectDescription.campaignType]) continue
      flags[samplePageLinks[i].projectDescription.campaignType] = true
      output.push(samplePageLinks[i].projectDescription.campaignType)
    }
    setProjectCampaignType(output)
    projectCard()
    function handleWindowResize() {
      projectCard()
      setWindowSize(getWindowSize())
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      setProject([])
      col = 1
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])
  function projectCard() {
    var arrowWithClass = document.getElementsByClassName("arrow-head")
    var infoWithClass = document.getElementsByClassName("project-info")
    if (arrowWithClass)
      for (let i = 0; i < arrowWithClass.length; i++) {
        arrowWithClass[i].classList.add("d-none")
      }
    if (infoWithClass)
      for (let i = 0; i < infoWithClass.length; i++) {
        infoWithClass[i].classList.add("d-none")
      }
  }
  const closeInfo = () => {
    var arrowWithClass = document.getElementsByClassName("arrow-head d-block")
    var infoWithClass = document.getElementsByClassName("project-info d-block")
    if (arrowWithClass)
      for (let i = 0; i < arrowWithClass.length; i++) {
        arrowWithClass[i].classList.add("d-none")
        arrowWithClass[i].classList.remove("d-block")
      }
    if (infoWithClass)
      for (let i = 0; i < infoWithClass.length; i++) {
        infoWithClass[i].classList.add("d-none")
        infoWithClass[i].classList.remove("d-block")
      }
  }

  return (
    <Layout>
      <Header />
      <>
        <Seo title="Project Gallery" />
        <div className="container project-gallery">
          <div className="project-gallery-home my-4 my-md-5">
            <h1 className="project-gallery-heading mars-blue mars-centra-bold-font border-left-mars-teal text-start my-3 my-md-4 ps-2">
              <b className="mars-centra-bold-font">
                Gallery of Promotions Sites
              </b>
            </h1>
            <div className="d-flex align-items-start flex-wrap flex-md-nowrap my-md-4">
              <span className="mars-blue mars-centra-bold-font fs-3">
                Filter&nbsp;by&nbsp;Campaign&nbsp;Type&nbsp;:
              </span>
              <div className="d-flex align-items-center flex-wrap mt-3 mt-md-0">
                {projectCampaignType.map(campaign => {
                  return (
                    <button
                      type="button"
                      className="btn btn-outline ms-md-2 mb-3 me-2 me-md-0 rounded-pill filters"
                      onClick={e => {
                        e.preventDefault()
                        if (e.target.className.includes("active")) {
                          e.target.classList.remove("active")
                          var filter = project.filter(project => {
                            return (
                              project.projectDescription.campaignType !==
                              campaign
                            )
                          })
                          if (filter.length === 0) setProject(samplePageLinks)
                          else setProject(filter)
                        } else {
                          e.target.classList.add("active")
                          var filter = samplePageLinks.filter(project => {
                            return (
                              project.projectDescription.campaignType ===
                              campaign
                            )
                          })
                          if (samplePageLinks.length === project.length)
                            setProject(filter)
                          else
                            setProject(previous => {
                              var combineArray
                              return (combineArray = previous.concat(filter))
                            })
                        }
                      }}
                    >
                      {campaign}
                    </button>
                  )
                })}
                <button
                  type="button"
                  className="btn btn-primary clear-all rounded-pill ms-2 mb-3 me-2 me-md-0"
                  onClick={() => {
                    var activeFilters =
                      document.querySelectorAll(".filters.active")
                    if (activeFilters.length > 0) {
                      for (let i = 0; i < activeFilters.length; i++)
                        activeFilters[i].classList.remove("active")

                      setProject(samplePageLinks)
                    }
                  }}
                >
                  Clear all
                </button>
              </div>
            </div>
            <div className="row justify-content-start g-3">
              {project.map((link, i) => {
                return (
                  <React.StrictMode key={i}>
                    <div className="text-decoration-none col-sm-6 col-md-4 col-lg-3 col-12 project-info-card">
                      <div
                        className="text-center project-card position-relative"
                        onClick={() => {
                          setProjectInfo(link)
                          var colTemp
                          if (windowSize?.innerWidth >= 992)
                            switch (windowSize?.innerWidth >= 992) {
                              case i < 4:
                                colTemp = 1
                                break
                              case i >= 4 && i < 8:
                                colTemp = 2
                                break
                              case i >= 8 && i < 12:
                                colTemp = 3
                                break
                              default:
                                colTemp = 4
                                break
                            }
                          else if (
                            windowSize?.innerWidth >= 768 &&
                            windowSize?.innerWidth <= 992
                          )
                            switch (
                              windowSize?.innerWidth >= 768 &&
                              windowSize?.innerWidth <= 992
                            ) {
                              case i < 3:
                                colTemp = 1
                                break
                              case i >= 3 && i < 6:
                                colTemp = 2
                                break
                              case i >= 6 && i < 9:
                                colTemp = 3
                                break
                              case i >= 9 && i < 12:
                                colTemp = 4
                                break
                              default:
                                colTemp = 5
                                break
                            }
                          else if (
                            windowSize?.innerWidth >= 575 &&
                            windowSize?.innerWidth <= 768
                          )
                            switch (
                              windowSize?.innerWidth >= 575 &&
                              windowSize?.innerWidth <= 768
                            ) {
                              case i < 2:
                                colTemp = 1
                                break
                              case i >= 2 && i < 4:
                                colTemp = 2
                                break
                              case i >= 4 && i < 6:
                                colTemp = 3
                                break
                              case i >= 6 && i < 8:
                                colTemp = 4
                                break
                              case i >= 8 && i < 10:
                                colTemp = 5
                                break
                              case i >= 10 && i < 12:
                                colTemp = 6
                                break
                              default:
                                colTemp = 7
                                break
                            }
                          else
                            switch (windowSize?.innerWidth <= 575) {
                              default:
                                colTemp = i + 1
                                break
                            }

                          var card = document.getElementById(
                            `card-row-${colTemp}`
                          )
                          console.log(colTemp)

                          closeInfo()

                          var arrow = document.getElementById(`arrow-${i}`)
                          arrow?.classList?.remove("d-none")
                          card?.classList?.remove("d-none")
                          arrow?.classList.add("d-block")
                          card?.classList.add("d-block")
                        }}
                      >
                        <div className="card-height position-relative">
                          <img
                            className="img-fluid banners"
                            alt={`${link.title} banner`}
                            src={link.imgUrl}
                          />
                        </div>
                        <div
                          className="w-100"
                          style={{
                            backgroundColor: `${link?.colors?.background}`,
                          }}
                        >
                          <p className="my-3 project-gallery-title text-white mars-centra-font">
                            {link.title}
                          </p>
                        </div>
                        <div
                          className="arrow-head d-none"
                          id={`arrow-${i}`}
                          style={{
                            borderTopColor: `${link?.colors?.background}`,
                          }}
                        ></div>
                      </div>
                    </div>
                    {windowSize?.innerWidth >= 992 &&
                      ((i + 1) % 4 === 0 || i + 1 === project.length) && (
                        <ProjectDescriptionCard
                          projectInfo={projectInfo}
                          closeInfo={closeInfo}
                          col={col++}
                        />
                      )}
                    {windowSize?.innerWidth >= 768 &&
                      windowSize?.innerWidth <= 992 &&
                      ((i + 1) % 3 === 0 || i + 1 === project.length) && (
                        <ProjectDescriptionCard
                          projectInfo={projectInfo}
                          closeInfo={closeInfo}
                          col={col++}
                        />
                      )}
                    {windowSize?.innerWidth >= 575 &&
                      windowSize?.innerWidth <= 768 &&
                      ((i + 1) % 2 === 0 || i + 1 === project.length) && (
                        <ProjectDescriptionCard
                          projectInfo={projectInfo}
                          closeInfo={closeInfo}
                          col={col++}
                        />
                      )}
                    {windowSize?.innerWidth <= 575 && (
                      <ProjectDescriptionCard
                        projectInfo={projectInfo}
                        closeInfo={closeInfo}
                        col={col++}
                      />
                    )}
                  </React.StrictMode>
                )
              })}
            </div>
          </div>
        </div>
      </>
      <Footer />
    </Layout>
  )
}
function getWindowSize() {
  if (typeof window !== "undefined") {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  } else return null
}
const ProjectDescriptionCard = ({ projectInfo, closeInfo, col }) => {
  return (
    <div className="col-12 mt-0 text-start project-info" id={`card-row-${col}`}>
      <div className="project-description-card p-3">
        <i
          className="fa fa-close text-gray position-absolute close"
          onClick={closeInfo}
        ></i>
        <h6 className="mars-blue mars-centra-bold-font">
          {projectInfo?.projectDescription?.category}
        </h6>
        <h2 className="text-black mars-centra-bold-font">
          {projectInfo?.projectDescription?.name}
        </h2>
        <p className="text-black mars-centra-font">
          {projectInfo?.projectDescription?.description}
        </p>
        <div className="row align-items-center">
          <div className="col-md-6">
            <p className="text-black mars-centra-font d-flex">
              <span className="mars-centra-bold-font">
                Campaign&nbsp;Type:&nbsp;
              </span>
              <span>
                {projectInfo?.projectDescription?.campaignType
                  ? projectInfo?.projectDescription?.campaignType
                  : "N/A"}
              </span>
            </p>
            <p className="text-black mars-centra-font d-flex">
              <span className="mars-centra-bold-font">
                Target&nbsp;Market:&nbsp;
              </span>
              {projectInfo?.projectDescription?.targetMarket
                ? projectInfo?.projectDescription?.targetMarket
                : "N/A"}
            </p>
            <p className="text-black mars-centra-font d-flex">
              <span className="mars-centra-bold-font">
                Technology&nbsp;Stack:&nbsp;
              </span>
              {projectInfo?.projectDescription?.technologyStack
                ? projectInfo?.projectDescription?.technologyStack
                : "N/A"}
            </p>
          </div>
          <div className="col-md-6">
            <p className="text-black mars-centra-font d-flex">
              <span className="mars-centra-bold-font">
                Development&nbsp;Time:&nbsp;
              </span>
              {projectInfo?.projectDescription?.developmentTime
                ? projectInfo?.projectDescription?.developmentTime
                : "N/A"}
            </p>
            <p className="text-black mars-centra-font d-flex">
              <span className="mars-centra-bold-font">
                Campaign&nbsp;Year:&nbsp;
              </span>
              {projectInfo?.projectDescription?.campaignYear
                ? projectInfo?.projectDescription?.campaignYear
                : "N/A"}
            </p>
            <p className="text-black mars-centra-font d-flex">
              <span className="mars-centra-bold-font">
                Website&nbsp;Analytics:&nbsp;
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: projectInfo?.projectDescription?.websiteAnalytics
                    ? projectInfo?.projectDescription?.websiteAnalytics
                    : "N/A",
                }}
              ></span>
            </p>
          </div>
          {typeof projectInfo.url === "object" ? (
            <div className="d-flex justify-content-center align-items-center flex-column flex-lg-row">
              {projectInfo.url.map(item => {
                return (
                  <p className="link-visit cursor-pointer text-center me-2">
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white text-decoration-none mars-centra-bold-font"
                    >
                      {item.name}
                    </a>
                  </p>
                )
              })}
            </div>
          ) : (
            <p className="link-visit cursor-pointer text-center mx-auto">
              <a
                href={projectInfo.url}
                target="_blank"
                rel="noreferrer"
                className="text-white text-decoration-none mars-centra-bold-font"
              >
                Click here for Website Experience
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectGallery
